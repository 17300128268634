import { FilterDropdownProps } from 'antd/es/table/interface'
import { FC } from 'react'
import { FilterConfig, PaginationConfig } from '@netpurpose/types'
import AlphabetFilter from './AlphabetFilter'
import { BooleanFilter } from './BooleanFilter'
import DateFilter from './DateFilter'
import EnumFilter from './EnumFilter'
import FilterIcon from './FilterIcon'
import { FilterProps, FilterType } from './filterTypes'
import NumberFilter from './NumberFilter'

const getFilterComponent = <Model, ExtraInputProps>(
  filterType: FilterType,
): FC<FilterProps<Model, ExtraInputProps>> => {
  switch (filterType) {
    case 'alpha':
      return AlphabetFilter
    case 'date':
      return DateFilter
    case 'number':
      return NumberFilter as unknown as FC<FilterProps<Model, ExtraInputProps>>
    case 'enum':
      return EnumFilter as unknown as FC<FilterProps<Model, ExtraInputProps>>
    case 'boolean':
      return BooleanFilter
    default:
      throw new Error(`unknown filter type: ${filterType}`)
  }
}

export const getFilterColumnConfig = <Model, ExtraInputProps = unknown>(
  filterType: FilterType,
  dataIndex: keyof Model,
  filterConfig: FilterConfig<Model>,
  paginationConfig: PaginationConfig,
  inputProps?: ExtraInputProps,
) => {
  const FilterComponent = getFilterComponent<Model, ExtraInputProps>(filterType)
  const filterIsActive = filterConfig.isActive(dataIndex)

  return {
    filterDropdown: (props: FilterDropdownProps) => (
      <FilterComponent
        filterConfig={filterConfig}
        paginationConfig={paginationConfig}
        dataIndex={dataIndex}
        inputProps={inputProps || ({} as ExtraInputProps)}
        {...props}
      />
    ),
    filterIcon: () => <FilterIcon active={filterIsActive} />,
  }
}
